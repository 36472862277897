@import "../../../../../../../../Project/Camfil/code/ui/styles/Camfil/imports";

// Setting min-height breaks align-items: center in IE11
// Workaround: https://github.com/philipwalton/flexbugs/issues/231

$name: '.Hero';
$header-height-desktop: 134px;
$header-height-md: 155px;
$header-height-sm: 110px;

#{$name} {
    min-height: 10rem;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50%;
    position: relative;
    overflow: hidden;

    @include media-breakpoint-up(md) {
        height: 15rem;
        min-height: 20rem;
    }

    @include media-breakpoint-up(lg) {
        height: 20rem;
        min-height: 25rem;
    }

    @include media-breakpoint-up(xl) {
        height: 20rem;
        min-height: 30rem;
    }

    &.fullscreen {
        height: calc(100vh - #{$header-height-sm});

        @include media-breakpoint-up(md) {
            height: calc(100vh - #{$header-height-md});
        }

        @include media-breakpoint-up(xl) {
            height: calc(100vh - #{$header-height-desktop});
        }

        #{$name}-btnDown {
            //display: none;
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            padding: 3rem;
            border: 0;
            text-align: center;
            z-index: 10;
            background-color: transparent;
            color: $white;
            animation-name: bounce;
            animation-duration: 1s;
            animation-direction: alternate;
            animation-timing-function: cubic-bezier( .5, 0.05, 1, .5);
            animation-iteration-count: infinite;

            &:hover {
                cursor: pointer;
            }

            &Icon {
                height: 2rem;
                color: $white;
                width: auto;
            }
        }
    }

    &-image {
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        width: 100%;
        min-height: 100%;
        background-size: cover;
        background-position: 50%;
    }

    &-video {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        background-size: cover;
        object-fit: cover;
        transform: translate(-50%,-50%);
    }

    &-placeholderTop {
        position: relative;
        z-index: 2;
        padding: 0;
    }

    &-image {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: -1;
        width: 100%;
        min-height: 100%;
        object-fit: cover;
    }

    &-title {
        margin: 0;
        line-height: 1;
        font-size: 1.8rem;
        color: $white;

        @include media-breakpoint-up(md) {
            font-size: 3rem;
        }

        @include media-breakpoint-up(xl) {
            font-size: 4rem;
        }
    }

    &-intro {
        font-family: $headings-font-family;
        font-size: 1rem;
        color: $white;

        @include media-breakpoint-up(md) {
            font-size: 1.75rem;
        }
    }

    @include media-breakpoint-up(xl) {
        //padding-bottom: 8rem;
        + .container-fluid {
            position: relative;
            margin-top: -8rem;
            background-color: $white;
            border-radius: 3px 3px 0 0;

            &:after {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                height: 15rem;
                z-index: -1;
                box-shadow: 0 0 10px rgba(0,0,0,.15);
            }
        }
    }
}

@keyframes bounce {
    from {
        transform: translate3d(0, 0, 0);
    }

    to {
        transform: translate3d(0, 1rem, 0);
    }
}

html {
    scroll-behavior: smooth;
}
